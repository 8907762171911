import React from 'react'
import Images from '../Images/Images'

function Footer() {

    const date = new Date().getFullYear()
    // console.log("dateeee", date)

    return (
        <div className='footer-main mt-5 mb-2'>
            <div>
                <hr className='footer-hr' />
                <div className='row' >
                    <div className='col-lg-6 col-sm-12 col-12 col-md-6'>
                        <div className='link-section-main'>
                            <div className='footer-links'>


                                <a href='/'> Home/</a>
                                <a href='/contact'>Contact/</a>
                                <a href=''>Imprint/</a>
                                <a href=''>Sitemap </a>


                            </div>

                            <div>
                               <a href="https://www.linkedin.com/company/enexio-power-cooling-solution-india-pvt-ltd/" target="_blank" rel="noreferrer"> <img src={Images.linkedin} className='social-icons' /></a>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='copyright-text'>
                            <p>© {date} ENEXIO ACC GMBH. ALL RIGHTS RESERVED.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer