import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems'
import AlexSystem from '../../components/CoolingSolutions/AlexSystem'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'


function AlexSystemPage() {


    var ImageProps = Images.acc
    

    const email = "sales@enexio.com";

    const key_facts = {
        title: "KEY FACTS",
        key_points: [
            "Bonding by brazing",
            "Bundle preassembly at ground level",
            "Self-supporting structure eliminates tube support straps, side walls, and A-frame for tube bundle support",
            "Zero dead zone",
            "High resistance to corrosion",
            "High stability",
            "Tube design and fin geometry allow a design with lower fan power consumption and corresponding lower sound power level",
            "Excellent heat transfer properties",
            // "No tube vibration or distortion possible",
            "Low steam side pressure drop",
            "High pressure fin cleaning possible - Smooth fins have a reduced tendency to fouling",
            "Life span of 25 years (checked by corrosion test)",
        ]
    };


    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <AlexSystem />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default AlexSystemPage