import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../routes';

const AppBreadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);


    const getBreadcrumbPath = (path) => {
        const route = routes.find((r) => r.path.includes(`/${path}`));
        return route ? route : null;
    };

    // const cleanPathSegment = (segment) => {
    //     return segment.replace(/-/g, ' ');
    // };

    // const hyphenToCamelCase = (str) => {
    //     return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    // };

    const hyphenToTitleCase = (str) => {
        return str
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className='breadcrumbs-main'>
            <Link to="/">Home</Link>&nbsp;

            {pathnames.map((path, index) => {
                const breadcrumbRoute = getBreadcrumbPath(path);

                if (breadcrumbRoute) {
                    const isLast = index === pathnames.length - 1;
                    // const cleanedPath = breadcrumbRoute.path.replace(/-/g, ' ');
                    // console.log("aji", isLast, index, pathnames, breadcrumbRoute);
                    // console.log("vvivi", pathnames)

                    return (
                        <span key={index}>
                            {' / '}&nbsp;
                            {isLast ? (
                                <Link to={breadcrumbRoute.path}>
                                    <b>
                                        {breadcrumbRoute.name}
                                        {/* {hyphenToTitleCase(breadcrumbRoute.name)} */}
                                    </b>
                                </Link>

                            ) : (
                                <>
                                    <Link to={breadcrumbRoute?.ParentPath}>
                                        {/* {path} */}
                                        {hyphenToTitleCase(path)}
                                    </Link>

                                </>

                            )}
                        </span>
                    );
                }

                return null;
            })}
        </div>
    );
};
export default AppBreadcrumbs