import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './app.css';


const PagesCarousel = ({ pageImages }) => {
    const sliderRef = useRef(null);

    useEffect(() => {

    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
    };

    return (
        <div className='bannercarousel-main mb-5'>
            <Slider {...settings} ref={sliderRef}>
                {pageImages.map((obj) => (
                    <div key={obj.id}>
                        <img src={obj.image} alt={`Slide ${obj.id}`} className='w-100' style={{ minHeight: "370px" }} />
                        <div className='headtext-section'>
                            {obj.title && <p>{obj.title}</p>}

                        </div>
                    </div>
                ))
                }
            </Slider >
        </div >
    );
};

export default PagesCarousel;
