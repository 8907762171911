import React from "react";
import WhoWeArePage from "./Pages/AboutusPage/WhoWeArePage";
import OurManagementPage from "./Pages/AboutusPage/OurManagementPage";
import NewsPage from "./Pages/AboutusPage/NewsPage";
import CorporateGovernancePage from "./Pages/AboutusPage/CorporateGovernancePage";
import QualityHsePage from "./Pages/AboutusPage/QualityHsePage";
import HistoryPage from "./Pages/AboutusPage/HistoryPage";
import VacanciesPage from "./Pages/VacanciesPage/VacanciesPage";
import AirCooledCondensersPage from "./Pages/CoolingSolutionPage/AirCooledCondensersPage";
import ClassicAirCooledCondensersPage from "./Pages/CoolingSolutionPage/ClassicAirCooledCondensersPage";
import InAirPage from "./Pages/CoolingSolutionPage/InAirPage";
import AlexSystemPage from "./Pages/CoolingSolutionPage/AlexSystemPage";
import WetCoolingTowersPage from "./Pages/CoolingSolutionPage/WetCoolingTowersPage";
import NDCTPage from "./Pages/CoolingSolutionPage/NDCTPage";
import IDCTPage from "./Pages/CoolingSolutionPage/IDCTPage";
import CtComponentsPage from "./Pages/CoolingSolutionPage/CtComponentsPage";

//ct components separate landing page for each components
import ModularSplashFillPage from "./Pages/CoolingSolutionPage/CtComponents/ModularSplashFillPage"
import DriftEliminatorsPage from "./Pages/CoolingSolutionPage/CtComponents/DriftEliminatorsPage"
import FlutedTypePage from "./Pages/CoolingSolutionPage/CtComponents/FlutedTypePage"
import SprayNozzlesPage from "./Pages/CoolingSolutionPage/CtComponents/SprayNozzlesPage"

import AirCooledRebundlingPage from "./Pages/AfterSalesAndServicesPage/AirCooledRebundlingPage";
import CombinedDryAndWetPage from "./Pages/AfterSalesAndServicesPage/CombinedDryAndWetPage";
import DryAndWetCoolingPage from "./Pages/AfterSalesAndServicesPage/DryAndWetCoolingPage";
import WccAccPage from "./Pages/AfterSalesAndServicesPage/WccAccPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import ReferencePage from "./Pages/ReferencePage/ReferencePage";
import AfterSalesandServicesPage from "./Pages/AfterSalesAndServicesPage/AfterSalesandServicesPage";
import AboutPage from "./Pages/AboutusPage/AboutPage"
import HybridCoolingDelugeAccPage from './Pages/CoolingSolutionPage/HybridCoolingDelugeAccPage'

const routes = [
    { path: '/homepage/', name: 'Home' },
    // { path: '/aboutus/', name: 'About Us', ParentName: 'About Us', ParentPath: '/aboutus/', element: AboutPage },
    { path: '/about-us/who-we-are/', name: 'Who we are', ParentName: 'About Us', ParentPath: '/about-us/who-we-are/', element: WhoWeArePage },
    { path: '/about-us/our-management/', name: 'Our Management', ParentName: 'About Us', ParentPath: '/about-us/our-management/', element: OurManagementPage },
    { path: '/about-us/corporate-governance/', name: 'Corporate Governance', ParentName: 'About Us', ParentPath: '/about-us/corporate-governance/', element: CorporateGovernancePage },

    // { path: '/about-us/news-exhibitions/', name: 'News', ParentName: 'About Us', ParentPath: '/about-us/news-exhibitions/', element: NewsPage },

    { path: '/about-us/quality-policy/', name: 'Quality Policy', ParentName: 'About Us', ParentPath: '/about-us/quality-policy/', element: QualityHsePage },
    { path: '/about-us/history/', name: 'History', ParentName: 'About Us', ParentPath: '/about-us/', element: HistoryPage },

    //Cooling Solutions
    { path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/', name: 'Air Cooled Condensers', ParentName1: 'Cooling Solutions', ParentName2: 'Dry Cooling Systems', ParentPath: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/', element: AirCooledCondensersPage },
    { path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/', name: 'Classic Air Cooled Condenser', ParentName1: 'Cooling Solutions', ParentName2: 'Dry Cooling Systems', ParentPath: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/', element: ClassicAirCooledCondensersPage },
    { path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/', name: 'Induced Draft Air Cooling Condenser - InAir', ParentName1: 'Cooling Solutions', ParentName2: 'Dry Cooling Systems', ParentPath: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/', element: InAirPage },
    { path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/alex-system/', name: 'Aluminium Finned Steel Tube System | ALEX-System', ParentName1: 'Cooling Solutions', ParentName2: 'Dry Cooling Systems', ParentPath: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/alex-system/', element: AlexSystemPage },
    { path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/hybrid-cooling-deluge-acc/', name: 'Hybrid Cooling Deluge ACC', ParentName1: 'Cooling Solutions', ParentName2: 'Dry Cooling Systems', ParentPath: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/hybrid-cooling-deluge-acc/', element: HybridCoolingDelugeAccPage },
    { path: '/cooling-solutions/wet-cooling-towers/', name: 'Wet Cooling Towers', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/', element: WetCoolingTowersPage },
    { path: '/cooling-solutions/wet-cooling-towers/NDCT/', name: 'Natural Draft Cooling Towers', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/NDCT/', element: NDCTPage },
    { path: '/cooling-solutions/wet-cooling-towers/IDCT/', name: 'Induced Draft Cooling Towers', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/IDCT/', element: IDCTPage },
    { path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/', name: 'Cooling Tower Components', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/', element: CtComponentsPage },
    { path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/NET-150-modular-splash-fill/', name: 'NF 20/NET 150 Modular Splash Fill', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/NET-150-modular-splash-fill/', element: ModularSplashFillPage },
    { path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/drift-eliminator/', name: 'Drift Eliminator', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/drift-eliminator/', element: DriftEliminatorsPage },
    { path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/film-fill-cross-fluted-type/', name: 'Film Fill - Cross - Fluted Type', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/film-fill-cross-fluted-type/', element: FlutedTypePage },
    { path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/spray-nozzles/', name: 'Spray Nozzles', ParentName1: 'Cooling Solutions', ParentName2: 'Wet Cooling Towers', ParentPath: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/spray-nozzles/', element: SprayNozzlesPage },


    //AfterSalesAndServices
    { path: '/after-sales-and-services/', name: 'After Sales & Services', ParentName: 'After Sales & Services', ParentPath: '/after-sales-and-services/', element: AfterSalesandServicesPage },
    { path: '/after-sales-and-services/dry-wet-cooling-services/', name: 'Dry & Wet Cooling Services', ParentName: 'After Sales & Services', ParentPath: '/after-sales-and-services/dry-wet-cooling-services/', element: DryAndWetCoolingPage },
    { path: '/after-sales-and-services/combined-dry-wet-cooling-systems/', name: 'PAC - Combined Dry - Wet Cooling Systems', ParentName: 'After Sales & Services', ParentPath: '/after-sales-and-services/combined-dry-wet-cooling-systems/', element: CombinedDryAndWetPage },
    { path: '/after-sales-and-services/wcc-acc/', name: 'Conversion Of WCC - ACC', ParentName: 'After Sales & Services', ParentPath: '/after-sales-and-services/wcc-acc/', element: WccAccPage },
    { path: '/after-sales-and-services/air-cooled-condenser-rebundling/', name: 'Air Cooled Condenser Rebundling', ParentName: 'After Sales & Services', ParentPath: '/after-sales-and-services/air-cooled-condenser-rebundling/', element: AirCooledRebundlingPage },

    //References
    { path: '/references/', name: 'References', ParentName: 'References', ParentPath: '/references/', element: ReferencePage },

    //Contacts
    { path: '/contact/', name: 'Contact', ParentName: 'Contacts', ParentPath: '/contact/', element: ContactPage },

    //Vacancies
    { path: '/vacancies/', name: 'Vacancies', ParentName: 'Vacancies', ParentPath: '/vacancies/', element: VacanciesPage },

]
export default routes;