import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'


const CombinedDryAndWet = () => {
    var ImageProps = Images.CombinedDryandWet

    return (
        <div className='combined-dry-and-wet-main'>
            <div className='heading-text mb-3'>
                <h1>COMBINED DRY/WET COOLING SYSTEMS </h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>WE PROVIDE SUSTAINABLE SYSTEMS WITH A STRONG ECONOMIC BASIS </h2>
            </div>
            <div id="c1437" className='headtext-section'>
                <p className='lead'>
                    While wet cooling ensures high efficiency for the power generation cycle, the associated evaporation and blow-down of cooling water imposes various environmental and ecological impacts:  </p>
                <div id="c1438" className='heading-text corporate-governance'>
                    <ul>
                        <li>
                            The humid plume emitted by the wet cooling tower contributes to visible fog formation and icing of roads in cold weather.
                        </li>
                        <li>
                            The constant evaporation and blow-down of cooling water requires substantial amount of make-up water, that curtails its availability for municipality, industrial and agricultural consumption.
                        </li>

                    </ul>
                </div>
                <p>In order to eliminate this problem, cooling towers use the principle of combined wet and dry cooling. In this system an intense mixing of the air streams from the dry section and the wet section of the cooling tower takes place to obtain discharge air conditions which will avoid the development of plumes. </p>
                <div className='mb-3 headtext-section '>
                    <img src={ImageProps} alt="CombinedDryandWet" width="100%" />
                    <p className='csc-space-before-20'>ENEXIO Combined dry/ wet Cooling System (PAC-System) </p>
                </div>
            </div>

            <div id="c1437" className='headtext-section'>
                <h3 >
                    PARALLEL CONDENSING SYSTEM (PAC)
                </h3>
                <p>A wet / dry system is a cooling process whereby a water-cooled condenser runs parallel to an air-cooled condenser. The heated cooling water from the water-cooled condenser is recooled by a cooling tower. The synergy of this process is that two well established cooling processes are combined and make use of the advantages of dry and wet cooling.</p>
                {/* <div id="c1438" className='heading-text '>
                    <ul>
                        <li>
                            Reduced water consumption comparing to 100% Wet Cooling System
                        </li>
                        <li>
                            No plume formation in winter because dry cooling only is possible
                        </li>
                        <li>Better energy generation in the warm seasons comparing to dry cooling </li>
                        <li>Smaller investments comparing to dry cooling </li>
                        <li>Smaller footprint comparing to dry cooling </li>
                        <li>Reduced power consumption for fan operation comparing to dry cooling </li>
                    </ul>
                </div> */}
            </div>

        </div>

    )
}

export default CombinedDryAndWet