import React, { useState } from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
// import alxml from '../../assets/images/png/alxml_.png'
import PagesCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"


const AlexSystem = () => {

    const pageImages = [


        {
            id: 1,
            image: Images.alexSystem_pageImg1,
            title: "Conventional finned tube bundles need A-supports"
        },
        {
            id: 2,
            image: Images.alexSystem_pageImg2,
            title: "Patented ENEXIO self-supporting finned tube bundle with integrated headers"
        },
        {
            id: 3,
            image: Images.alexSystem_pageImg3,
            title: "Only separation walls and hoist beam needed"
        },
        {
            id: 4,
            image: Images.alexSystem_pageImg4,
            title: "No obstacles behind the heat exchange surface"
        },
        {
            id: 5,
            image: Images.alexSystem_pageImg5,
            title: "Classic Air Cooled Condenser with ENEXIO ALEX-System"
        },

    ]

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        e_mail: '',
        phone: '',
        fax: '',
        company: '',
        designation: '',
        postalcode: '',
        city: '',
        country: '',
        subject: 'Please choose subject...', // Set default subject
        confirm: [
            {
                id: 'confirm1',
                label: 'The Construction of the tube bundle for heat rejection, forms the heart and mind of Air Cooled Condenser design',
                checked: false,
            },
            {
                id: 'confirm2',
                label: 'Critical manufacturing process creating the metallurgical bonding of aluminium fins to large cross section of flat steel tubes is achieved by critical brazing process technology',
                checked: false,
            },
            {
                id: 'confirm3',
                label: '    The brazing process assures high strength and corrosion resistance with assurance of 100% bonding through the lifetime',
                checked: false,
            },
            {
                id: 'confirm4',
                label: '    The fin geometry and design is optimized using finite element analysis and extensive thermal performance measurement',
                checked: false,
            },
            {
                id: 'confirm5',
                label: '    The performance of single row condenser is thus achieved for maximum heat transfer efficiency and minimum fan power consumption',
                checked: false,
            },
            {
                id: 'confirm6',
                label: '        The fins compactness and sturdiness ensure structural integrity while ensuring superior thermal performance',
                checked: false,
            },
            {
                id: 'confirm7',
                label: '        The parallel and plane nature of fin tube construction uniquely ensures low fouling and time spans between cleaning cycles can be longer than with other conventional single-row designs',
                checked: false,
            },
            {
                id: 'confirm8',
                label: '        Due to its high strength fin design and unlike other single row tubes bundles, the ENEXIO ALEX Tube Bundle can be cleaned with pressure water jet, achieving very thorough cleaning with reduced cleaning water consumption.',
                checked: false,
            },
            // Add more points as needed
        ],
    });


    const bundlePoints = [
        'Bonding by brazing',
        'Bundle preassembly at ground level',
        'Self-supporting structure eliminates tube support straps, side walls, and A-frame for tube bundle support.',
        'Zero dead zone',
        'High resistance to corrosion',
        " High stability ",
        " Tube design and fin geometry allow a design with lower fan power consumption and corresponding lower sound power level ",
        " Excellent heat transfer properties ",
        " No tube vibration or distortion possible   ",
        " Low steam side pressure drop ",
        " High pressure fin cleaning possible - Smooth fins have a reduced tendency to fouling ",
        " Life span of over 25 years (checked by corrosion test) ",
    ];
    const handleChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            country: selectedOption.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here using formData
        console.log('Form submitted:', formData);
    };


    return (
        <div className='cooling-solutions alex-system'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>ALEX-SYSTEM: ALUMINIUM FINNED STEEL TUBE SYSTEM</h1>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>  PATENTED SELF-SUPPORTING FINNED TUBE BUNDLE</h2>
                </div>

                <div id="c1437" className='headtext-section'>
                    <p className='lead'>
                        The ALEX system of ENEXIO consists of an aluminium coated steel flat duct.  Aluminium finnings between the flat ducts are hard-soldered to the aluminium coating of the flat duct.  The solder fills the gap between the fin root and the core tube completely to achieve excellent heat transfer properties.  </p>
                    <p>Conventional fin tube bundles need A-supports to carry the weight of tube bundles but the patented ENEXIO self-supporting fin tube bundle with integrated headers needs no support: There are only separation walls and hoist beams needed which results in material savings and a very low erection time.  </p>

                </div>
                <div id="c1438" className='heading-text'>
                    <ul>
                        <li>
                            Own Heat Exchanger bundle production
                        </li>
                        <li>
                            Bundle preassembly at ground level
                        </li>
                        <li>
                            Only one small crane needed (1 big lift = 2 bundles)
                        </li>

                    </ul>

                </div>
                <div id="c1434">
                    <article>
                        <p>
                            <span className="lead">
                                YOU CAN TRUST THE EXPERTS
                            </span>{' '}
                        </p>
                    </article>
                </div>
                <div className='alexSystem mb-4' id="table-container-whiteGrey" >
                    <table>
                        <thead>
                            <tr>
                                <th>Manual hand welding</th>
                                <th>Robot welding</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="list-cell">
                                    <span className="list-mark">&#8226;</span>
                                    No guarantee of continuously welding quality can be insured
                                </td>
                                <td className="list-cell">
                                    <span className="list-mark">&#8226;</span>
                                    Robot welding meets always same quality standards
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell">
                                    <span className="list-mark">&#8226;</span>
                                    Higher risk of manual welding defects
                                </td>
                                <td className="list-cell">
                                    <span className="list-mark">&#8226;</span>
                                    Less welding defects with robot
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell"></td>
                                <td className="list-cell">
                                    <span className="list-mark">&#8226;</span>
                                    Increased productivity in tube-to-tube sheet welding
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='alexmsubli'>
                    <h3 className='subalsxm'>ALEX Single row tube design with aluminium finned flat shaped tubes</h3>
                    {formData.confirm.map((point, index) => (
                        <div key={point.id} className="powermail_check_outer heading-text">
                            <ul>
                                <li key={index}> {point.label}</li>
                            </ul>
                        </div>
                    ))}
                </div>

                <div className='pysctics'>
                    <h3 className='subalsxm'>PHYSICAL CHARACTERISTICS</h3>
                    <div className="">
                        <div className='heading-text mynmk heading-text'>
                            <ul>
                                <li>    Flat shaped steel with aluminium cladding</li>
                                <li>        Meandering form of aluminium</li>
                                <li>        Connection by brazing</li>
                                <li>        Single tube row design</li>
                                <li>        Tube ends welded to tube sheets</li>
                            </ul>
                        </div>
                        {/* <div className='pysy mt-5'>
                            <div className='img-div text-center'>
                                <img src={Images.brkp} alt="" width="50%" /></div>
                        </div> */}
                    </div>

                </div>

                {/* <div className="bundle heading-text ">
                    <h3 className='subalsxm pyshk'>BENEFITS OF ALEX TUBE BUNDLE AT A GLANCE</h3>
                    <div className=' clpyshk'>
                        <div className='budloi heading-text'>
                            <ul>
                                {bundlePoints.map((point, index) => (
                                    <li key={index}>{point}</li>
                                ))}
                            </ul>
                        </div>
                       
                    </div>
                </div> */}

                <div className='heading-text'>
                    <h3>REFERENCES AROUND THE GLOBE</h3>
                    <PagesCarousel pageImages={pageImages} />
                </div>
            </div>

        </div >
    )
}

export default AlexSystem