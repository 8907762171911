import React, { Fragment, useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routes from '../routes';
import Header from '../commonComponents/Header/Header';
import Footer from '../commonComponents/Footer/Footer';
import ScrollToTop from '../commonComponents/ScrollToTop/ScrollToTop';
import Error404 from '../commonComponents/Error404/Error404';


function DefaultLayout() {


    return (
        <div>
            <ScrollToTop />

            <Header />
            <div className='container'>
                <div>

                    <Routes>
                        {routes.map((route, idx) => {
                            return (
                                route.element && (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        element={<route.element />}
                                    />
                                )
                            );
                        })}
                        <Route path={'*'} name={'Erro 404'} element={<Error404 />} />
                    </Routes>


                </div>
                <Footer />

            </div>
        </div>
    )
}

export default DefaultLayout