import React from 'react'
import { Link } from 'react-router-dom'
import "../../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../../commonComponents/Images/Images'


const FlutedType = () => {
    const image2 = Images.NF;
    const image4 = Images.Drift;
    const image6 = Images.FilmFill;
    const image8 = Images.SprayNozzle;


    return (
        <div className='cooling-solutions ctcomponents'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>FILM FILL - CROSS - FLUTED TYPE</h1>
                </div>
                {/* <div className='sub-heading'>
                    <h2 className=''>COOLING TOWER COMPONENTS</h2>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>COMPONENTS AND FILLS FOR EVAPORATIVE COOLING TOWERS</h2>
                </div>
                <div className='headtext-section'>
                    <p className="lead">Evaporative cooling towers are still considered the most efficient way of cooling process water at industrial sites all over the world. With economic and ecological factors always an important consideration, construction and operation of wet cooling towers necessitates the use of efficient fills and drift eliminators. As the pioneer of plastic components for cooling tower applications we help our customers to meet their requirements.</p>
                </div> */}


                <div className='mb-4'>
                    {/* <div className='sub-heading'>
                        <h2 className='ice-blue'>FILM FILL - CROSS - FLUTED TYPE</h2>
                    </div> */}
                    <div className='text-center mb-3'>
                        {/* <img src={image6} alt="Rcc2" width="100%" /> */}
                    </div>
                    <div id="c1438" className='heading-text corporate-governance'>
                        <ul>
                            <li>
                                The foils are reinforced at the outer edges and are therefore very stable.
                            </li>
                            <li>
                                Excellent heat and mass transfer established due to the higher residence time distribution between air and water with the help of cross-fluted structure.
                            </li>
                            <li>Due to their high specific surface area and higher heat and mass transfer area, also which results in lower pressure drop across the fill cross sections. </li>
                            <li>Longer durability due to chemical, bacterial and UV resistance of PVC, subject to maintaining the defined water quality.  </li>
                            <li>Self-supporting structure – High bearing capacity achieved by large number of connecting points. </li>
                            <li>Less downtime for installation. </li>
                        </ul>
                    </div>
                    <div id="table-container ct-components">
                        <table>
                            <thead>
                                <tr>
                                    <th>MAXIMUM LENGTH
                                    </th>
                                    <th>MAXIMUM WIDTH</th>
                                    <th>MAXIMUM HEIGHT</th>
                                    <th>CONTINUOUS OPERATING TEMPERATURE</th>
                                    <th>MAXIMUM OPERATING TEMPERATURE<br />
                                        (Short Time)  </th>
                                </tr>
                                <tr className='table-units'>
                                    <th>mm  </th>
                                    <th>mm      </th>
                                    <th> mm      </th>
                                    <th> ⁰C </th>
                                    <th> ⁰C   </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="list-cell text-center">
                                        2438
                                    </td>
                                    <td className="list-cell text-center">
                                        610
                                    </td>
                                    <td className="list-cell text-center">305/610  </td>
                                    <td className="list-cell text-center">0 - 55</td>
                                    <td className="list-cell text-center">60  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div >
    )
}

export default FlutedType