import React from 'react'
import AppBreadcrumbs from '../../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../../commonComponents/Images/Images'
import Sidebar from '../../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../../utilities/sidebarItems';
import ModularSplashFill from '../../../components/CoolingSolutions/CtComponents/ModularSplashFill'
import Banner from '../../../commonComponents/Banner/Banner'



function ModularSplashFillPage() {

    var ImageProps = Images.CT_Components1



    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <ModularSplashFill />
                </div>

            </div>

        </div>
    )
}

export default ModularSplashFillPage