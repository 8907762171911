import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import Images from '../../commonComponents/Images/Images'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Vacancies from '../../components/Aboutus/Vacancies'

const VacanciesPage = () => {
    var ImageProps = Images.VacanciesBanner

    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>

                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <Vacancies />
                </div>

            </div>


        </div>
    )
}

export default VacanciesPage