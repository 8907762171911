import React from 'react'
import { Link } from 'react-router-dom'
import Images from '../../commonComponents/Images/Images'



function WhoWeAreContent() {
    const image1 = Images.AirCooledImg3;
    const image2 = Images.CoolingTowerlogo;
    const image3 = Images.Servicelogo;

    return (
        <div className='whowearecontent-main'>
            <div className='heading-text mb-3'>
                <h1>ABOUT ENEXIO</h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>A LONG-STANDING HISTORY BASED ON EXPERIENCE AND TRUST</h2>
            </div>
            <div className='headtext-section'>
                <p className="lead">
                    {/* - <em>ranges from <Link to="/cooling-solutions/dry-cooling-systems/air-cooled-condensers/" className="internal-link">Air Cooled Condensers</Link> and <Link to="/cooling-solutions/wet-cooling-towers/" className="internal-link">Wet Cooling Towers</Link> including <Link to="/after-sales-services/dry-wet-cooling-services/" className="internal-link">Service for Wet and Dry applications</Link></em> – */}
                    <strong>The former Power Cooling division of GEA Group AG operates separately and independently under the name ENEXIO.</strong>
                </p>
                <div id="c1438" className='heading-text headtext-section'>
                    <p className="lead2">
                        <strong>Our Products</strong>  </p>
                    <ul>
                        <li>
                            <em>  <Link to="/cooling-solutions/dry-cooling-systems/air-cooled-condensers/">Air Cooled Condensers</Link></em>
                        </li>
                        <li>
                            <em> <Link to="/cooling-solutions/wet-cooling-towers/">Wet Cooling Towers</Link></em>
                        </li>
                        <li>
                            <em>  <Link to="/after-sales-and-services/">After Sales & Services</Link></em>
                        </li>
                    </ul>



                </div>

                <p>ENEXIO is a reflection of what we do and what we have accomplished as pioneer in the field of industry-, process-, and power plant cooling as well as process industry cooling over decades. At the same time, our name is a promise to our customers and business partners – <strong> ENEXIO stands for Energy. Engineering. Excellence.</strong>
                </p>

                <p>With our experience and pioneering spirit in engineering, manufacturing and service, we deliver state of the art solutions for power plants, water and wastewater applications – always inspired by our profound sense of responsibility for the overall management of resources and a clean environment. We deliver excellence and achieve customer satisfaction everywhere in the world. Our global network of employees work with an unceasing commitment to high quality, ecology and cost effective products and services.&nbsp;
                </p>
                <p>What our commitment to Energy, Engineering and Excellence means for you is that we are a reliable partner who is always there to meet your expectations with superior results. More than 80 years in the market and a great deal of success have made us a leading manufacturer and solutions provider worldwide of both wet and dry cooling systems.&nbsp;
                </p>
                <p>Depending on customer requirements, we can provide any type of cooling solution for power generation efficiency while assuring cooling water economy. Our rich product portfolio ranges from all dry air cooled condenser (ACC) technologies to all wet cooling towers, with limitless dry/wet combination systems and evaporative add-on solutions in between.
                </p>
                <p>In addition to our core power cooling solutions, we also offer cooling tower components that ensure a high degree of operating safety and environmental protection.
                </p>
                <p>The diversity of our designs provides customers with extremely eco-friendly solutions and a minimum of operating and maintenance costs. Underlining our responsibility as a reliable partner, we also offer customers comprehensive maintenance and spare parts service, developing and manufacturing all of the main components at our own production sites.
                </p>
                <p> Experience ENEXIO!</p>



            </div>
            <div class="csc-space-before-10">
                <hr></hr>
            </div>

            <div id="c8016" className='headtext-section heading-text'>
                <h2 className='ice-blue'>The organization</h2>
                <article>
                    <p className="lead">
                        <strong>The ENEXIO Group is based on three main pillars: Dry Cooling Solutions, Wet Cooling solutions, Service, including specific technical resolutions and spare parts. </strong>
                    </p>
                </article>
                <p>Business Unit Dry Cooling Solutions includes all activities related to Air-Cooled Condenser and Indirect Dry Cooling technology with its own heat exchangers bundle production in India and a strong global network. The Service division covers all relevant parts of After Sales & Service in regards to Dry and Wet Cooling, including critical components as well as Mechanical draft and Natural draft new build-projects worldwide.
                </p>
            </div>
            <div id="c8022" class="hidden-xs">
                <div class="row">
                    <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                        <Link class="productbox eq-item" to="/cooling-solutions/dry-cooling-systems/air-cooled-condensers/">
                            <div class="image">
                                <img src={image1} alt="AirCooledImg1" />
                            </div>
                            <div class="text">
                                <h3 class="title">Dry Cooling Systems</h3>
                                <div class="link">go to Solutions</div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                        <Link class="productbox eq-item" to="/cooling-solutions/wet-cooling-towers/">
                            <div class="image">
                                <img src={image2} alt="CoolingTowerlogo" />
                            </div>
                            <div class="text">
                                <h3 class="title">Wet Cooling Towers</h3>
                                <div class="link">go to Solutions</div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                        <Link class="productbox eq-item" to="/after-sales-and-services/">
                            <div class="image">
                                <img src={image3} alt="Servicelogo" />
                            </div>
                            <div class="text">
                                <h3 class="title">After<br /> Sales & Services</h3>
                                <div class="link">go to Services</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default WhoWeAreContent