import React, { useState, useEffect } from 'react';
import Header from '../commonComponents/Header/Header'
import Images from '../commonComponents/Images/Images'
import $ from 'jquery';
import 'flexslider/flexslider.css';
import 'flexslider';
import BannerCarousel from '../commonComponents/bannerCarousel/bannerCarousel';
import HomepageTextContent from '../components/Homepage/HomepageTextContent';
import HomepagetextTable from '../components/Homepage/HomepagetextTable';
import Footer from '../commonComponents/Footer/Footer';
// import Modal from '../components/Modal/modal';
import BannerCarouselNew from '../commonComponents/BannerCarouselNew/BannerCarouselNew';


const HomepageLayout = () => {
    
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // useEffect(() => {
    //   setIsModalOpen(true); // Open the modal on page load
    // }, []);
  
    // const closeModal = () => {
    //   setIsModalOpen(false);
    // };

    const BannerImages = [
        {
            id: 1,
            image: Images.banner1
        },
        {
            id: 2,
            image: Images.banner2
        },
        // {
        //     id: 3,
        //     image: Images.banner3
        // },
        // {
        //     id: 4,
        //     image: Images.banner4
        // },
        {
            id: 5,
            image: Images.banner5
        },
        {
            id: 6,
            image: Images.banner6
        }, 
        {
            id: 7,
            image: Images.banner7
        }, 
        {
            id: 8,
            image: Images.banner8
        },
        {
            id: 9,
            image: Images.banner9
        },

    ]


    return (
        <div>
            <Header />

            
            <div className='container'>
                <div>
                    {/* <BannerCarousel /> */}
                    <BannerCarouselNew bannerImages={BannerImages} />
                </div>
                <div>
                    <HomepageTextContent />
                </div>
                {/* <div>
                    <HomepagetextTable />
                </div> */}
                <Footer />
                {/* Modal Component 
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <img src={Images.Expo} alt="invitation" className='invitation'/>
                    </Modal>*/}
            </div>

        </div>
    )
}

export default HomepageLayout