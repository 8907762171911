import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import NDCT from '../../components/CoolingSolutions/NDCT'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'


function NDCTPage() {
    var ImageProps = Images.ndct

    const email = "sales@enexio.com";


    const key_facts = {
        title: "ADVANTAGES",
        key_points: [
            "Maximum cost-effectiveness over the entire life cycle",
            "State of the art with 2H cooling fills",
            "Superior product quality in accordance with our rigorous standards",
            "High degree of delivery reliability",
            "Reduced total cost",
            "Reliable project management",
            "Energy efficiency",
            "Low maintenance and energy costs",
            "Highly efficient components",
        ]
    };


    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <NDCT />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default NDCTPage