import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import PagesCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"
import Images from '../../commonComponents/Images/Images'


const NDCT = () => {
    const image1 = Images.wetcoolingsystem1;

    const pageImages = [
        {
            id: 1,
            image: Images.ndct1,
            title: "ENEXIO NDCT for 2 x 660 MW Power Plant - TPCIL, Krishnapatnam in the year 2016"
        },
        {
            id: 2,
            image: Images.ndct2,
            title: "ENEXIO NDCT for 1 x 800 MW Power Plant - SDSTPS, Krishnapatnam in the year 2021"
        },
        {
            id: 3,
            image: Images.ndct3,
            title: "ENEXIO NDCT for 2 x 500 MW Power Plant - MAHGENCO, Chandrapur in the year 2014"

        },
        {
            id: 4,
            image: Images.ndct4,
            title: "ENEXIO NDCT for 1 x 600 MW Power Plant - TANGEDCO, Mettur in the year 2012"

        },
        {
            id: 5,
            image: Images.ndct5,
            title: "ENEXIO NDCT for 2 x 600 MW Power Plant - RRVUNL, Kalisindh in the year 2015"

        },
        {
            id: 6,
            image: Images.ndct6,
            title: "ENEXIO NDCT for 2 x 660 MW Power Plant - SEIL Energy India Ltd. in the year 2015"

        },
    ]
    return (
        <div className='cooling-solutions NDCT'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>NATURAL DRAFT COOLING TOWER</h1>
                </div>

                <div id="c1437" className='headtext-section'>
                    <p className='lead'>
                        ENEXIO’s Natural draft cooling towers are designed based on the in-house thermal design software, after continuous study, development and expertise in designing & building Natural draft cooling towers for more than 25 years.    </p>
                    <p>We take utmost care in the thermal design of the cooling tower thereby ensuring that the plant operates with maximum efficiency and reliability, based on the right selection of heat transfer media & the hydraulic system, with our expertise & technical know-how. The wide range of designs available with us ensures that our solutions satisfy all cost and environmental requirements, and with optimum operational and service cost. The almost complete lack of moving parts allows for high operating reliability and minimal maintenance requirements.   </p>
                    <p>This translates into market stability, on which our customers rely, both today and in future.  </p>
                    <p>Enexio has core competence in this field with our customers benefitting from our specialist knowledge and proven quality products. </p>
                    <p>While designing Natural draft cooling towers, it becomes essential that the cost effectiveness be maximized over the entire life cycle using the optimised Hydraulic design ensuring uniform distribution over the entire plan area. Hence, it is imperative that the tower is designed so that they are tailor-made to the client’s requirements where one has to take into account wide variety of factors ranging from the water quality to the local meteorological requirements.</p>
                    <p>ENEXIO takes pride in designing the 2nd tallest Natural Draft Cooling Tower in the world, of M/s. RRVUNL, with the total height of 202 Mtr. The tower has been in successful operation since 2015. </p>
                </div>
                <div className='mb-3 text-center'>
                    <img src={image1} alt="" width={"100%"} />
                </div>
                <div id="table-container-milstone-project" className='mb-3'>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2">MILESTONE PROJECT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="list-cell">
                                    LOCATION
                                </td>
                                <td className="list-cell">
                                    Kalisindh, Rajasthan
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell">
                                    CLIENT
                                </td>
                                <td className="list-cell">
                                    M/s. Rajasthan Rajya Vidyut Utpadan Nigam Limited
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell">PROJECT</td>
                                <td className="list-cell">
                                    2 x 660 MW Thermal power project
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell">HIGHLIGHTS</td>
                                <td className="list-cell">
                                    Height of 202 m & base diameter of 141 m
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell">SPOTLIGHT</td>
                                <td className="list-cell">
                                    High Range & High volumetric flow, Lower approach,
                                    Lower relative humidity, Lower rain density,
                                    with draft margin of 3%.
                                </td>
                            </tr>
                            <tr>
                                <td className="list-cell">YEAR OF OPERATION</td>
                                <td className="list-cell">
                                    2015
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="c1437" className='headtext-section mb-4'>
                    <article>
                        <p>
                            Other major references include <strong> APGENCO, APPDCL, NCC, TPCIL, TANGEDCO.</strong> ENEXIO is also proud to have supplied modular splash / trickle fill for the first indigenously designed Pressurized Heavy Water Reactors of 700 MWe for Nuclear Power plant at Kakrapar Unit 3 & 4 at Gujarat. With this beginning, ENEXIO is entrusted with repeat order for supply of modular NF-20 Splash Fills for 2 Units of 700 MWe at NPCIl, Ravatbhatta, Unit 7& Unit 8.
                        </p>
                    </article>
                </div>
                <div>
                    <PagesCarousel pageImages={pageImages} />
                </div>
            </div>

        </div>
    )
}

export default NDCT