import React from 'react'
import "../../assets/css/CorporateGovernance.css"
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import { AboutUsitems } from '../../utilities/sidebarItems'
import CorporateGovernanc from '../../components/Aboutus/CorporateGovernanc'
import Banner from '../../commonComponents/Banner/Banner'
import Images from '../../commonComponents/Images/Images'





const CorporateGovernancePage = () => {
  var ImageProps = Images.ccpg

  // const CorporateGovernancePage = () => {
  //   const [showImage, setShowImage] = useState(true);

  //   const handleToggle = () => {
  //     setShowImage(!showImage);
  //   };


  return (
    <div>
      <Banner ImageProps={ImageProps} />
      <AppBreadcrumbs />
      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-12'>
          <Sidebar sidebarItems={AboutUsitems} />
        </div>
        <div className='col-lg-9 col-md-9 col-sm-12'>
          <CorporateGovernanc />
        </div>
        <div className='col-lg-3 col-md-3 col-sm-12'>
          {/* <div className="sidebar contact-sidebar aboutus-sections-sub">
            <div id="c1435" className="well well-sm">
              <h4 className="csc-firstHeader">Contact</h4>
              <div className="img-center img-above">
                <div className="img-wrap">
                  <div className="img-center-outer">
                    <div className="img-center-inner">
                      <figure className="image">
                        <img
                          src={Images.presidentImg}
                          width="559"
                          height="393"
                          className="img-responsive img-content"
                          alt="U.Stadermann"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div id="contact" className="dynContent corporate-contact">
                  <div class="" id="contactbox">
                    <div class="businesscard">
                      <div id="companyname" class="">
                        <strong> Please contact:</strong><br />
                        <p className='m-0'> T. Parasuram</p>
                        <strong> Executive Director </strong>
                      </div>

                    </div>
                    <div id="adresse"> Kurios Software Park – <br />Third Floor,</div>
                    <div id="town"> Plot No: 24, II Cross Street,<br /> Ambattur Industrial Estate,</div>
                    <div id="location"> Chennai - 600 058, Tamilnadu, India</div>
                    <dl className="contactdata ">
                      <dd id="mobile" className="">
                        <strong>Phone :</strong> 044 69 250 250
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>

  )
}

export default CorporateGovernancePage