import React from 'react'
import { Link } from 'react-router-dom'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'


const CtComponents = () => {
    const image2 = Images.NF;
    const image4 = Images.Drift;
    const image6 = Images.FilmFill;
    const image8 = Images.SprayNozzle;


    return (
        <div className='cooling-solutions ctcomponents'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>COOLING TOWER COMPONENTS</h1>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>COMPONENTS AND FILLS FOR EVAPORATIVE COOLING TOWERS</h2>
                </div>
                <div className='headtext-section'>
                    <p className="lead">Evaporative cooling towers are still considered the most efficient way of cooling process water at industrial sites all over the world. With economic and ecological factors always an important consideration, construction and operation of wet cooling towers necessitates the use of efficient fills and drift eliminators. As the pioneer of plastic components for cooling tower applications we help our customers to meet their requirements.</p>


                </div>
                <div id="c8022" class="hidden-xs">
                    <div class="row">
                        <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                            <Link class="productbox eq-item" to="/cooling-solutions/wet-cooling-towers/cooling-tower-components/NET-150-modular-splash-fill/">
                                <div class="image">
                                    <img src={image2} alt="AirCooledImg1" />
                                </div>
                                <div class="text">
                                    <h3 class="title">NF 20/NET 150 MODULAR SPLASH FILL</h3>
                                    <div class="link">go to Solutions</div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                            <Link class="productbox eq-item" to="/cooling-solutions/wet-cooling-towers/cooling-tower-components/film-fill-cross-fluted-type/">
                                <div class="image">
                                    <img src={image6} alt="Servicelogo" />
                                </div>
                                <div class="text">
                                    <h3 class="title">FILM FILL - CROSS - FLUTED TYPE</h3>
                                    <div class="link">go to Services</div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                            <Link class="productbox eq-item" to="/cooling-solutions/wet-cooling-towers/cooling-tower-components/drift-eliminator/">
                                <div class="image">
                                    <img src={image4} alt="CoolingTowerlogo" />
                                </div>
                                <div class="text">
                                    <h3 class="title">DRIFT ELIMINATOR</h3>
                                    <div class="link">go to Solutions</div>
                                </div>
                            </Link>
                        </div>

                        <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                            <Link class="productbox eq-item" to="/cooling-solutions/wet-cooling-towers/cooling-tower-components/spray-nozzles/">
                                <div class="image">
                                    <img src={image8} alt="Servicelogo" />
                                </div>
                                <div class="text">
                                    <h3 class="title">SPRAY NOZZLES</h3>
                                    <div class="link">go to Services</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default CtComponents