import React from 'react'


const QualityHse = () => {


    return (
        <div className="main-content aboutus-sections-sub">
            <div id="c1490">
                <h1 className="csc-firstHeader">Quality Policy</h1>
            </div>
            <div id="c3722">
                <h2 className='ice-blue'>We are committed to ensure total customer and employee satisfaction by</h2>
            </div>

            <div className='headtext-section'>
                <article>
                    <p>
                        <span className="lead">
                            Each of our entities - comprising Production, Engineering, and Sales & Service functions - are responsible for setting up Quality objectives and targets as well as developing and implementing sustainable programs to achieve them. Our Quality Policy is based on four main pillars and reflects 100% what we do understand regarding QHSE:
                        </span>{' '}
                    </p>
                </article>
            </div>
            <div id="c1492" className='qualitylist heading-text'>
                <ul>
                    <li>Ensure high standards of quality of Product & Services, On-Schedule deliveries and complying with quality codes and standards.</li>
                    <li>Building quality culture through team work, employee empowerment and ensuring safe & healthy work Environment.</li>
                    <li>Identifying all risk arising from business process well in advance and effectively managing those risks.</li>
                    <li>Constantly monitoring and improving our quality management System.</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
        </div>
    )
}

export default QualityHse