import React from 'react'

function Banner({ ImageProps }) {
    return (
        <div className='banner-main'>
            <div>
                <img className='w-100' src={ImageProps} alt='banner-image' />
            </div>
        </div>
    )
}

export default Banner