import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import "../../assets/css/ContactSidebar.css";

const ContactSidebar = ({ email, contactInfo, downloads, showContactInfo, showDownloads, additionalUploads, recentlyPublished, key_facts }) => {


  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderAddress = () => {
    switch (selectedOption) {
      case '1':

        return (
          <>
            <div class="col-title" id="contactbox">
              <div class="businesscard">
                <div id="companyname" class="business-company">
                  <h4>ENEXIO Power Cooling Solutions India Pvt. Ltd. </h4>
                </div>
              </div>
              <div id="additional">Head Office</div>
              <div id="adresse">Kurios Software Park -<br />Third Floor,</div>
              <div id="town">Plot No: 24, II Cross Street, Ambattur Industrial Estate,</div>
              <div id="location">Chennai-600058,<br /> Tamil Nadu, India</div>
              {renderEmail()}

            </div>
          </>
        )

      case '2':
        return (
          <>
            <div class="col-title" id="contactbox">
              <div class="businesscard">

                <div id="companyname" class="business-company">
                  <h4>ENEXIO Power Cooling Solutions India Pvt. Ltd.</h4>
                </div>
              </div>
              <div id="additional">Mumbai Office</div>
              <div id="adresse">506, Prabhat Centre Annex, Sector – 1A, </div>
              <div id="town">Near MGM Hospital,<br /> C.B.D. Belapur, </div>
              <div id="location">Navi Mumbai – 400614, India </div>

              {/* {renderEmail()} */}
              <dl className="contactdata ">
                <dd id="email" className="email">
                  <a href="mailto:sales@enexio.com">Email: <div className='emailid'>sales@enexio.com</div></a>
                </dd>
              </dl>


            </div >

            <div class="col-title" id="contactbox">
              <div class="businesscard">

                <div id="companyname" class="business-company">
                  <h4>ENEXIO Power Cooling Solutions India Pvt. Ltd.</h4>
                </div>
              </div>
              <div id="additional">Delhi Office</div>
              <div id="adresse">“NUKLEUS”, 501-A, 5th Floor, Salcon Rasvilas Building, </div>
              <div id="town">District Centre, Saket. </div>
              <div id="location">New Delhi – 110017, India</div>

              {/* {renderEmail()} */}
              <dl className="contactdata ">
                <dd id="email" className="email">
                  <a href="mailto:sales@enexio.com">Email: <div className='emailid'>sales@enexio.com</div></a>
                </dd>
              </dl>

            </div>

            <div class="col-title" id="contactbox">

              <div id="additional">Kolkata Office</div>

              {/* {renderEmail()} */}
              <dl className="contactdata ">
                <dd id="email" className="email">
                  <a href="mailto:sales@enexio.com">Email: <div className='emailid'>sales@enexio.com</div></a>
                </dd>
              </dl>

            </div>
          </>
        );
      case '3':
        return (
          <>
            <div class="col-title" id="contactbox">
              <div class="businesscard">
                <div id="companyname" class="business-company">
                  <h4>CT Components Manufacturing Facility </h4>
                </div>
              </div>
              <div id="additional">ENEXIO Power Cooling Solutions India Pvt. Ltd.  </div>
              <div id="adresse">No.167/1, 173, Arani - Puduvayal Road Durainallur,  </div>
              <div id="town">Puduvayal, Ponneri Taluk,  </div>
              <div id="location">Thiruvallur- 601 206,<br /> Tamil Nadu, India </div>
              {renderEmail()}


            </div>

            <div class="col-title" id="contactbox">
              <div class="businesscard">
                <div id="companyname" class="business-company">
                  <h4>Alex Tube Bundle Manufacturing Facility</h4>
                </div>
              </div>
              <div id="additional">ENEXIO Power Cooling Solutions India Pvt. Ltd. </div>
              <div id="adresse">No.9/ 2A, S. No. 20/1 <br /> and No. 21/1A  </div>
              <div id="town">Kandamangalam Village, Sriperumbundur taluk, </div>
              <div id="location">Kanchipuram district - 602105, Tamil Nadu, India </div>
              {renderEmail()}

            </div>
          </>
        );
      // case '4':
      //   return (
      //     <>
      //       <div class="col-title" id="contactbox">
      //         <div class="businesscard">
      //           <div id="companyname" class="business-company">
      //             <h4>ENEXIO Germany GmbH</h4>
      //           </div>
      //         </div>
      //         <div id="additional">Global Office</div>
      //         <div id="adresse">ENEXIO Germany GmbH </div>
      //         <div id="town">Dorstener Str. 18-29, 44651</div>
      //         <div id="location">Herne, Germany</div>
      //         {renderEmail()}

      //       </div>
      //     </>
      //   );
      default:
        return null;
    }
  };

  const renderEmail = () => {
    if (email) {
      return (
        <dl className="contactdata ">
          <dd id="email" className="email">
            <a href={`mailto:${email}`}>Email: <div className='emailid'>{email}</div></a>
          </dd>
        </dl>

      );
    }
    return null;
  };

  // const renderEmail = (emails) => {
  //   return (
  //     <div>
  //       {emails.map((email, index) => (
  //         <dl key={index} className="contactdata">
  //           <dd id={`email${index + 1}`} className="email">
  //             <a href={`mailto:${email}`}>Email: <div className='emailid'>{email}</div></a>
  //           </dd>
  //         </dl>
  //       ))}
  //     </div>
  //   );
  // };


  return (
    <div className="sidebar contact-sidebar aboutus-sections-sub">
      {showContactInfo && (
        <div id="c1435" className="well well-sm">
          <h4 className="csc-firstHeader">Contact</h4>

          {/* <div className="img-center img-above">
            {contactInfo.image ? (<div className="img-wrap">
              <div className="img-center-outer">
                <div className="img-center-inner">
                  <figure className="image">
                    <img
                      src="/fileadmin/user_upload/news/U.Stadermann.png"
                      width="559"
                      height="393"
                      className="img-responsive img-content"
                      alt="U.Stadermann"
                    />
                  </figure>
                </div>
              </div>
            </div>) : <></>}
            <div className="text">
              <p>
                {contactInfo.name ? (<>
                  <strong>Please contact:</strong>
                  <br />
                  {contactInfo.name}
                  <br /></>) : <></>}
                <strong></strong>
                <div className="business-company">
                  <h4>{contactInfo.title}</h4>
                </div>
                <br />
                {contactInfo.address}
                {contactInfo.address.split('<br/>').map((line, index) => (
                  <React.Fragment key={index}>
                    {line.trim()}
                    <br />
                  </React.Fragment>
                ))}
                <br />
                <strong>Phone:</strong> {contactInfo.phone}
                <br />
                <strong>Fax:</strong> {contactInfo.fax}
                <br />
                <strong>
                  <Link to={contactInfo.email} className="mail">
                    E-Mail
                  </Link>
                </strong>
              </p>
            </div>
          </div> */}

          <div className="countrySelectionMode" id="contactSelect">
            <select name="country" id="country" onChange={handleSelectChange}>
              <option value="0">Select...</option>
              <option value="1">Head Office</option>
              <option value="2">Regional Office</option>
              <option value="3">Manufacturing Plants</option>
              {/* <option value="4">Global Office</option> */}
            </select>
          </div>
          <div class="businesscard">
                <div id="companyname" class="business-company">
                <Link to="/contact"><strong>For Enquries</strong> :
                  <span className='ice-blu'>click here...</span></Link>
                </div>
              </div>
          <div id="contact" className="dynContent">
            {renderAddress()}
          </div>

        </div>
      )}
      {
        key_facts && (
          <>
            <div id="c1435" className="well well-sm">
              <h4 className="csc-firstHeader">{key_facts.title}</h4>
              <div className="img-center img-above">
                <div className='mb-3'>
                  {key_facts.image}
                </div>
                <div className='key-facts'>
                  {key_facts.key_points && key_facts.key_points.length > 0 ? (
                    <ul className='key-points-marker'>
                      {key_facts.key_points.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  ) : null}
                  <p>{key_facts.para}</p>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        showDownloads && (
          <div id="c1436" className="well well-sm">
            <h4>Downloads</h4>
            <ul className="uploads uploads-0">
              {downloads.map((download, index) => (
                <li
                  key={index}
                  className={`li-${index % 2 === 0 ? 'even' : 'odd'} uploads-element uploads-element-pdf`}
                >
                  <span className="uploads-fileName">
                    {/* Add target="_blank" to open the link in a new tab */}
                    {/* <Link to={download.link} title={download.title} target="_blank" rel="noopener noreferrer">
                 {download.title}
               </Link> */}
                    {/* <a href={download.link}title={download.title} target='_blank' rel='noopener noreferrer'>  </a> */}
                    <a href={download.link} target="_blank"
                      rel="noreferrer">
                      {download.title}
                    </a>
                  </span>
                  <span className="uploads-fileSize">{download.fileSize}</span>
                </li>
              ))}
            </ul>
          </div>
        )
      }

      {
        recentlyPublished && (
          <>
            <div className='recentlypublished'>
              <h4 className='ice-blue'>Recently Published</h4>
              <img src={recentlyPublished} className='w-100' />
            </div>
          </>
        )
      }
    </div >
  );
};

// ContactSidebar.propTypes = {
//   contactInfo: PropTypes.shape({
//     //   name: PropTypes.string,
//     //   title: PropTypes.string,
//     //   address: PropTypes.string,
//     //   phone: PropTypes.string,
//     //   fax: PropTypes.string,
//     //   email: PropTypes.string,
//     email: PropTypes.string,
//   }),
ContactSidebar.propTypes = {
  email: PropTypes.string,
  key_facts: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  key_facts: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  showContactInfo: PropTypes.bool,
  showDownloads: PropTypes.bool,
  additionalUploads: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      fileSize: PropTypes.string,
    })
  ),
};

ContactSidebar.defaultProps = {
  showContactInfo: true,
  showDownloads: true,
  additionalUploads: [],
};

export default ContactSidebar;