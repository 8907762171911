import React from 'react'
import { Link } from 'react-router-dom'
import "../../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../../commonComponents/Images/Images'


const DriftEliminators = () => {
    const image2 = Images.NF;
    const image4 = Images.Drift;
    const image6 = Images.FilmFill;
    const image8 = Images.SprayNozzle;


    return (
        <div className='cooling-solutions ctcomponents'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>DRIFT ELIMINATOR</h1>
                </div>
                {/* <div className='sub-heading'>
                    <h2 className=''>COOLING TOWER COMPONENTS</h2>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>COMPONENTS AND FILLS FOR EVAPORATIVE COOLING TOWERS</h2>
                </div>
                <div className='headtext-section'>
                    <p className="lead">Evaporative cooling towers are still considered the most efficient way of cooling process water at industrial sites all over the world. With economic and ecological factors always an important consideration, construction and operation of wet cooling towers necessitates the use of efficient fills and drift eliminators. As the pioneer of plastic components for cooling tower applications we help our customers to meet their requirements.</p>
                </div> */}


                <div className='mb-4'>
                    {/* <div className='sub-heading'>
                        <h2 className='ice-blue'>DRIFT ELIMINATOR</h2>
                    </div> */}
                    <div className='text-center mb-3'>
                        {/* <img src={image4} alt="Rcc2" width="100%" /> */}
                    </div>
                    <div id="c1438" className='heading-text corporate-governance'>
                        <ul>
                            <li>
                                TAP 160 reduce water loss and emissions.
                            </li>
                            <li>
                                The chemical and UV resistance of PVC material ensures a long service life.
                            </li>
                            <li>TAP 160 remains stable and maintains its shape even under high temperatures.  </li>
                            <li>Quick and easy installation. </li>
                        </ul>
                    </div>
                    <div id="table-container ct-components">
                        <table>
                            <thead>
                                <tr>
                                    <th>MAXIMUM LENGTH</th>
                                    <th>MAXIMUM WIDTH</th>
                                    <th>MAXIMUM HEIGHT</th>
                                    <th>CONTINUOUS OPERATING TEMPERATURE</th>
                                    <th>MATERIAL  </th>
                                    <th>MAXIMUM OPERATING TEMPERATURE<br /> (Short Time) </th>

                                </tr>
                                <tr className='table-units'>
                                    <th> mm  </th>
                                    <th>mm      </th>
                                    <th> mm      </th>
                                    <th>⁰C </th>
                                    <th>  </th>
                                    <th>⁰C </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="list-cell text-center">
                                        6000
                                    </td>
                                    <td className="list-cell text-center">
                                        330
                                    </td>
                                    <td className="list-cell text-center">160 </td>
                                    <td className="list-cell text-center">0 - 60</td>
                                    <td className="list-cell text-center">PVC  </td>
                                    <td className="list-cell text-center">70  </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div >
    )
}

export default DriftEliminators