import React, { useEffect } from 'react';

import "../../assets/css/CoolingSolutions&SalesServices.css"
import PagesCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"
import Images from '../../commonComponents/Images/Images'


const InAir = () => {

    const pageImages = [
        {
            id: 1,
            image: Images.Inair10,
            title: "ENEXIO InAIR ACC for 1 x 500 MW Power Plant in CPV Valley , USA - 500 MW"

        },
        {
            id: 2,
            image: Images.Inair4,
            title: "ENEXIO InAIR ACC for 1 x 450 MW Power Plant in CPV Towantic, USA"

        },
        {
            id: 3,
            image: Images.Inair2,
            title: "ENEXIO InAIR ACC for  2 x 150 MW Power Plant in Sepco III - Salalah - Oman - 2016"

        },
        {
            id: 4,
            image: Images.Inair7,
            title: "ENEXIO InAIR ACC for 1 x 150 MW CSP plant - Noor Complex III in Marocco"

        },
        {
            id: 5,
            image: Images.Inair6,
            title: "ENEXIO InAIR ACC for 1 x 200 MW CSP plant - NOOR Complex II in Marocco"

        },
        {
            id: 6,
            image: Images.Inair15,
            title: "ENEXIO InAIR ACC for 2 x 60 MW Power Plant in China - 2015"

        },
    ]

    return (
        <div className='inair-main'>
            <div className='heading-text mb-3'>
                <h1>THE NEW INDUCED DRAFT AIR COOLING CONDENSER</h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>INAIR - THE INNOVATIVE AND INTELLIGENT AIR COOLED CONDENSER SYSTEM</h2>
            </div>
            <div id="c1437" className='headtext-section'>
                <p className="lead">From the company who invented the Air Cooled Condenser, comes the next generation of innovative and intelligent design. </p>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-5'>
                        <img src={Images.inair_pageImg} alt="" width="100%" />
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div id="c1437" className='headtext-section mb-5'>
                        <p>The InAIR combines proven components and technologies to yield the next generation in dry cooling: an Air Cooled Condenser featuring induced draft fans. Induced draft fans have been used reliably in the majority of power plant main cooling systems worldwide, like in wet cooling tower applications. Now, through innovative engineering and utilization of our self-supporting ALEX bundles, ENEXIO can offer the InAIR, an Air Cooled Condenser which offers the superior operational performance.</p>
                    </div>
                </div>
            </div>
            <div className='mb-4'>
                {/* <div className=''>
                    <img src={inair_pageImg} alt="" width="100%" />
                </div> */}
                {/* <div id="c1437" className='headtext-section'>
                    <p>
                        The next wave of imaginative and sensible design comes from ENEXIO who are inventor of  Air Cooled Condenser. </p>
                    <p>The InAIR blends proven components and technologies to yield the next generation in dry cooling: An Air Cooled Condenser featuring induced draft fans.  </p>
                    <p>Induced draft fans have been used reliably in the majority of power plant main cooling system, world-wide (e.g., wet cooling towers), but their application in ACCs have been limited due to higher costs. But through innovative engineering and utilization of our Patented self-supporting ALEX bundles, ENEXIO can now offer the InAIR, an ACC which offers the superior operational performance of Induced Draft fans with added savings in Structural quantity, Installation time and construction effort, Proven tube bundles and steam path unchanged. </p>
                    <p>The InAIR protects fans, motors and gearboxes against unnecessary vibrations by eliminating the fan bridge and reducing the impact of the wind at the fan inlet. These features increase the life expectancy of the rotating equipment.
                    </p>
                </div> */}
            </div>

            <div id="c1438" className='heading-text mb-4'>
                <h3 className=''>PRIMARY BENEFITS WITH INDUCED DRAFT FANS:</h3>
                <ul>
                    {/* <li>
                        PATENTED Worldwide
                    </li>
                    <li>
                        Less sensitivity to wind effects
                    </li>
                    <li>
                        Minimized air recirculation due to high exit velocities of air
                    </li>
                    <li>
                        Reduces auxiliary power demand due to lower air-side pressure drop
                    </li>
                    <li>
                        Reduced air inlet and total height of ACC: less visual impact
                    </li>
                    <li>
                        Smaller footprint of columns
                    </li>
                    <li>Reduction of steel structure quantities </li>
                    <li>Reduction of steel structural weight  </li>
                    <li>Reduced costs for construction </li>
                    <li>Reduced construction duration: decrease in man-hours for erection work </li>
                    <li>Easier per-assembly (mainly pre-assembly on ground) </li>
                    <li>Inherent design of supporting steel structure greatly reduced the requirement of scaffolding </li>
                    <li>Reduction In the length of the main steam duct </li>
                    <li>Reduced investment costs </li>
                    <li>Shorter delivery and erection periods</li>
                    <li>Lower power consumption for fan </li>
                    <li>Lower Static resistance on air inlet </li> */}
                    <li>Reduced air inlet and total height of ACC: less visual impact
                    </li>
                    <li> Smaller footprint of columns
                    </li>
                    <li> Reduction of steel structure quantities (up to -60%)
                    </li>
                    <li> Reduction of steel structure weight (up to -50%)
                    </li>
                    <li> Reduced costs for construction (-10% to -25%)
                    </li>
                    <li>Reduced construction duration: 20% to 30% decrease in man-hours for erection work
                    </li>
                    <li> Easier pre-assembly at grade
                    </li>
                    <li> Inherent design of supporting steel structure greatly reduces the requirement of scaffolding
                    </li>
                    <li> Reduction in the length of the main steam duct
                    </li>
                    <li> Reduced investement costs
                    </li>
                    <li>Shorter delivery and erection periods
                    </li>
                    <li> Patented around the world</li>
                </ul>

            </div>
            <div>
                <PagesCarousel pageImages={pageImages} />
            </div>
            <div id='c8113' class="csc-space-before-20 headtext-section mb-4">
                <h3 className='mb-3'> NO FAN BRIDGE NEEDED</h3>
                <p className='mb-3'>In a classic forced draft Air Cooled Condenser, the fans are exposed to an elevated level of dynamic loading. The vibrational effect of dynamic loading can be mitigated by calculation and design – but not eliminated. The InAIR Air Cooled Condenser resolves this weakness in the Classic ACC design.
                </p>
                <div>
                    <img src={Images.inair_reference_world_map} alt="hybridReference image" width="100%" />
                </div>
            </div>
            <div id="c1438" className='heading-text mb-4'>
                <h3 className=''>OPERATIONAL ADVANTAGES</h3>
                <ul>
                    <li>InAIR requires no fan bridge, resulting in less vibration stress which leads to longer lifetime of rotating equipment
                    </li>
                    <li> Minimized air recirculation due to high exit velocities of air
                    </li>
                    <li> Less sensivity to wind effects
                    </li>
                </ul>
                <div>
                    <img src={Images.inair_pageImg2} alt="hybridReference image" width="100%" />
                </div>
            </div>
        </div>



    )
}

export default InAir