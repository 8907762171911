import { React, useState } from 'react'
import "../../assets/css/Contact.css";
import "../../assets/css/Reference.css";
import { Link, NavLink } from 'react-router-dom';
import Images from '../../commonComponents/Images/Images';

const Reference = () => {

    const [selectedCategory, setSelectedCategory] = useState('all');

    const references = {
        'all': [
            // {
            //     title: 'CLASSIC AIR COOLED CONDENSERS (ACC)',
            //     imageSrc: Images.classic_acc,
            //     link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            // },

            // {
            //     title: 'THE NEW INDUCED DRAFT AIR COOLING CONDENSER',
            //     imageSrc: Images.inair,
            //     link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            // },
            // {
            //     title: 'NATURAL DRAFT COOLING TOWER',
            //     imageSrc: Images.ndct,
            //     link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            // },
            {
                title: 'ENEXIO Classic ACC for 775 MW CCPP',
                imageSrc: Images.Classic,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 45 MW Waste-to-energy Power Plant',
                imageSrc: Images.Classic2,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 413 MW CCPP',
                imageSrc: Images.Classic3,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 1x250 MW CCPP - Altomonte, Italy in the year 2004',
                imageSrc: Images.Classic4,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 1x750 MW Coal Fired Power Plant - Kogan Greek, Australia in the year 2006',
                imageSrc: Images.Classic5,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 805 MW CCPP',
                imageSrc: Images.Classic6,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 2x750 MW CCPP - Erbil, Iraq in the year 2013',
                imageSrc: Images.Classic7,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 6x740 MW Coal Fired Power Plant -  Eskoms Medupi Power Station, South Africa in the year 2008',
                imageSrc: Images.Classic8,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 6x665 MW Power Plant- Eskoms Matimba Power Station,South Africa in the year 1991',
                imageSrc: Images.Classic9,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x500 MW Power Plant in CPV Valley , USA - 500 MW',
                imageSrc: Images.Inair10,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x450MW Power Plant in CPV Towantic, USA',
                imageSrc: Images.Inair4,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for  2x150MW Power Plant in Sepco III - Salalah - Oman - 2016',
                imageSrc: Images.Inair2,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x150 MW CSP plant - Noor Complex III in Marocco',
                imageSrc: Images.Inair7,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x200 MW CSP plant - NOOR Complex II in Marocco',
                imageSrc: Images.Inair6,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 2x60 MW Power Plant in China - 2015',
                imageSrc: Images.Inair15,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO NDCT for 2x660 MW Power Plant - TPCIL, Krishnapatnam in the year 2016',
                imageSrc: Images.ndct1,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 1x800 MW Power Plant - SDSTPS, Krishnapatnam in the year 2021',
                imageSrc: Images.ndct2,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 2x500 MW Power Plant - MAHGENCO, Chandrapur in the year 2014',
                imageSrc: Images.ndct3,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 1x600 MW Power Plant - TANGEDCO, Mettur in the year 2012',
                imageSrc: Images.ndct4,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 2x600 MW Power Plant - RRVUNL, Kalisindh in the year 2015',
                imageSrc: Images.ndct5,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 2x660 MW Power Plant - SEIL Energy India Ltd. in the year 2015',
                imageSrc: Images.ndct6,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO IDCT for 1x180 MW Power Plant - Anrak Aluminium in Vizag',
                imageSrc: Images.idct1,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 4X135 MW Power Plant - KSK Warora',
                imageSrc: Images.idct2,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 2X660 MW Power Plant in NTPC SOLAPUR',
                imageSrc: Images.idct3,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 4X350 MW Power Plant - RKM Power, Chattisgarh',
                imageSrc: Images.idct4,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 1x80 MW Power Plant - Hindustan Zinc Zawar',
                imageSrc: Images.idct5,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 1x330 MW Power Plant - RRVUNL, Dolhpur',
                imageSrc: Images.idct6,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
        ],
        'Classic ACC': [
            {
                title: 'ENEXIO Classic ACC for 775 MW CCPP',
                imageSrc: Images.Classic,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 45 MW Waste-to-energy Power Plant',
                imageSrc: Images.Classic2,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 413 MW CCPP',
                imageSrc: Images.Classic3,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 1x250 MW CCPP - Altomonte, Italy in the year 2004',
                imageSrc: Images.Classic4,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 1x750 MW Coal Fired Power Plant - Kogan Greek, Australia in the year 2006',
                imageSrc: Images.Classic5,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 805 MW CCPP',
                imageSrc: Images.Classic6,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 2x750 MW CCPP - Erbil, Iraq in the year 2013',
                imageSrc: Images.Classic7,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 6x740 MW Coal Fired Power Plant -  Eskoms Medupi Power Station, South Africa in the year 2008',
                imageSrc: Images.Classic8,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            {
                title: 'ENEXIO Classic ACC for 6x665 MW Power Plant- Eskoms Matimba Power Station,South Africa in the year 1991',
                imageSrc: Images.Classic9,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            },
            // {
            //     title: 'ENEXIO Classic ACC in Leverkusen, Germany',
            //     imageSrc: Images.Classic10,
            //     link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            // },
            // {
            //     title: 'ENEXIO Classic ACC in South Africa',
            //     imageSrc: Images.Classic11,
            //     link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/',
            // },

        ],
        'InAIR ACC': [
            {
                title: 'ENEXIO InAIR ACC for 1x500 MW Power Plant in CPV Valley , USA - 500 MW',
                imageSrc: Images.Inair10,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x450MW Power Plant in CPV Towantic, USA',
                imageSrc: Images.Inair4,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for  2x150MW Power Plant in Sepco III - Salalah - Oman - 2016',
                imageSrc: Images.Inair2,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x150 MW CSP plant - Noor Complex III in Marocco',
                imageSrc: Images.Inair7,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 1x200 MW CSP plant - NOOR Complex II in Marocco',
                imageSrc: Images.Inair6,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },
            {
                title: 'ENEXIO InAIR ACC for 2x60 MW Power Plant in China - 2015',
                imageSrc: Images.Inair15,
                link: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/',
            },

        ],
        'NDCT': [
            {
                title: 'ENEXIO NDCT for 2x660 MW Power Plant - TPCIL, Krishnapatnam in the year 2016',
                imageSrc: Images.ndct1,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 1x800 MW Power Plant - SDSTPS, Krishnapatnam in the year 2021',
                imageSrc: Images.ndct2,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 2x500 MW Power Plant - MAHGENCO, Chandrapur in the year 2014',
                imageSrc: Images.ndct3,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 1x600 MW Power Plant - TANGEDCO, Mettur in the year 2012',
                imageSrc: Images.ndct4,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 2x600 MW Power Plant - RRVUNL, Kalisindh in the year 2015',
                imageSrc: Images.ndct5,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
            {
                title: 'ENEXIO NDCT for 2x660 MW Power Plant - SEIL Energy India Ltd. in the year 2015',
                imageSrc: Images.ndct6,
                link: '/cooling-solutions/wet-cooling-towers/NDCT/',
            },
        ],
        'IDCT': [
            {
                title: 'ENEXIO IDCT for 1x180 MW Power Plant - Anrak Aluminium in Vizag',
                imageSrc: Images.idct1,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 4X135 MW Power Plant - KSK Warora',
                imageSrc: Images.idct2,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 2X660 MW Power Plant in NTPC SOLAPUR',
                imageSrc: Images.idct3,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 4X350 MW Power Plant - RKM Power, Chattisgarh',
                imageSrc: Images.idct4,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 1x80 MW Power Plant - Hindustan Zinc Zawar',
                imageSrc: Images.idct5,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
            {
                title: 'ENEXIO IDCT for 1x330 MW Power Plant - RRVUNL, Dolhpur',
                imageSrc: Images.idct6,
                link: '/cooling-solutions/wet-cooling-towers/IDCT/',
            },
        ],


    };

    const handleTabClick = (category) => {
        setSelectedCategory(category);
    };


    return (
        <div>
            <div className="conatctMainkl">
                <h1 className="firstHeader">OUR REFERENCES </h1>
                <h2 className='ice-blue cnm'>GLOBAL AND LOCAL AT THE SAME TIME </h2>
            </div>
            <p class="lead newm">Our reputation is based on well-known references from numerous industrial and power plant locations across the globe. Convince yourself and have a look at our references listed below or contact us for more information. </p>

            <div><div class="button-group filter-button-group">
                <button className={`button ${selectedCategory === 'all' ? 'is-checked' : ''}`} onClick={() => handleTabClick('all')}>all</button>
                <button className={`button ${selectedCategory === 'Classic ACC' ? 'is-checked' : ''}`} onClick={() => handleTabClick('Classic ACC')}>Classic ACC</button>
                <button className={`button ${selectedCategory === 'InAIR ACC' ? 'is-checked' : ''}`} onClick={() => handleTabClick('InAIR ACC')}>InAIR ACC</button>
                <button className={`button ${selectedCategory === 'NDCT' ? 'is-checked' : ''}`} onClick={() => handleTabClick('NDCT')}>NDCT</button>
                <button className={`button ${selectedCategory === 'IDCT' ? 'is-checked' : ''}`} onClick={() => handleTabClick('IDCT')}>IDCT</button>

            </div>
            </div>

            <div className=" references row">
                {references[selectedCategory].map((reference, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-12 gallery-tab" key={index}>
                        <div className="card lkm">
                            <div className="card-body news">
                                <Link to={reference.link}>
                                    <img className='imgEnixl' src={reference.imageSrc} alt="" width={"100%"} />
                                    <div className="card-overlay top">
                                        <div className="card-body cardhover-para">
                                            <p>{reference.title}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}

export default Reference