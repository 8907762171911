import React from 'react'
import Images from '../../commonComponents/Images/Images'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import OurManagement from '../../components/Aboutus/OurManagement'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { AboutUsitems } from '../../utilities/sidebarItems';

function OurManagementPage() {
    var ImageProps = Images.whowearebanner

    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={AboutUsitems} />

                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <OurManagement />
                </div>

            </div>

        </div>
    )

}

export default OurManagementPage