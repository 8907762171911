import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/Contact.css"
import Select from 'react-select'
// Instead of importing 'countries' as default, import specific exports
import { countries as countryData } from 'countries-list'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import { Link } from "react-router-dom";

const countryOptions = Object.keys(countryData).map((countryCode) => ({
    // value: countryCode,
    value: countryData[countryCode].name,
    label: countryData[countryCode].name,
}));

const subjectOptions = [
    { value: 'Please choose category...', label: 'Please choose category*' },
    { value: 'Product', label: 'Product' },
    { value: 'Company', label: 'Company' },
    { value: 'General', label: 'General' },
];

const fieldLabels = {
    first_name: "First name",
    last_name: "Last name",
    email: "E-Mail",
    phone: "Phone",
    company_name: "Company name",
    designation: "Designation",
    city: "City",
    postal_code: "Postal code",
    country: "Country",
    product: "Category",
    subject: "Subject",
    message: "Message",
    confirm: "Confirmation",
};


const Contact = () => {
    const [sending, setSending] = useState(false)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        fax: '',
        company_name: '',
        designation: '',
        city: '',
        postal_code: '',
        country: '',
        product: "Please choose category...",
        subject: '',
        message: '',
        confirm: false,
    });

    const refs = {
        first_name: useRef(null),
        last_name: useRef(null),
        email: useRef(null),
        phone: useRef(null),
        company_name: useRef(null),
        designation: useRef(null),
        city: useRef(null),
        postal_code: useRef(null),
        country: useRef(null),
        product: useRef(null),
        subject: useRef(null),
        message: useRef(null),
        confirm: useRef(null),
    };



    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        if (name === "first_name" || name === "last_name") {
            const alphabeticValue = value.replace(/[^a-zA-Z]/g, "");
            setFormData((prevData) => ({
                ...prevData,
                [name]: alphabeticValue,
            }));
        } else if (name === "subject") {
            const alphabeticValue = value.replace(/[0-9]/g, "");
            setFormData((prevData) => ({
                ...prevData,
                [name]: alphabeticValue,
            }));
        }
        else if (name === "phone") {
            const numericValue = value.replace(/\D/g, "");
            const maxLength = 10;

            // Check for repeated sequences in phone number
            if (hasRepeatedSequence(numericValue, 6)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Phone number should not have a sequence of the same digit repeated more than five times."
                }));
            } else if (numericValue.length <= maxLength) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: numericValue,
                }));
                // Clear error if valid input
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: null
                }));
            }
        } else if (name === "postal_code") {

            const numericValue = value.replace(/\D/g, "");
            const maxLength = 6;

            if (numericValue.length <= maxLength) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: numericValue,
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: null
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Postal code must be exactly 6 digits."
                }));
            }
        } else if (name === "fax") {
            const numericValue = value.replace(/\D/g, "");
            setFormData((prevData) => ({
                ...prevData,
                [name]: numericValue,
            }));
            // Clear error if valid input
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null
            }));
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const hasRepeatedSequence = (value, maxLength) => {
        const repeatedPattern = new RegExp(`(.)\\1{${maxLength - 1},}`);
        return repeatedPattern.test(value);
    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: selectedOption ? selectedOption.value : ''
        });
    };

    const validate = () => {
        let formIsValid = true;
        const newErrors = {}

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            if ((!formData[key] && key !== 'fax') || (key === 'product' && formData[key] === 'Please choose category...')) {
                newErrors[key] = `${fieldLabels[key]} is required.`;
                formIsValid = false;
            }
        });

        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address.";
            formIsValid = false;
        }

        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = "Please enter a valid phone number.";
            formIsValid = false;
        }

        setErrors(newErrors)
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            setSending(true);

            // emailjs service id and tempalte id here
            const template_ID = 'template_dpcrflr';
            const service_ID = 'service_f0g19kr';
            const public_Key = 'dQq7WUluIMfZjpMhr';

            // template params
            const templateParams = {
                ...formData,
                reply_to: 'sales@enexio.com'
            };

            // sending email using emailjs
            emailjs.send(service_ID, template_ID, templateParams, public_Key)
                .then(
                    (response) => {
                        console.log('SUCCESS!', response.status, response.text);
                        setSending(false);
                        resetForm()
                        toast.success(
                            <div className="toast-div"><p>Thanks for submitting the form!</p>
                                <Link to="/"><i class="fas fa-arrow-left"></i> Return to home</Link >
                            </div>,
                            { className: 'custom-toast' }

                        );
                    },
                    (error) => {
                        console.log('FAILED...', error);
                        toast.error('Form submission failed.');
                        setSending(false);
                    },
                );

            console.log("Form submitted:", formData);
        } else {
            console.log("Form validation failed:", errors);


            // Scroll to the first error message
            // console.log("First error key:", Object.keys(errors)[0]);
            if (Object.keys(errors).length > 0) {
                const firstErrorKey = Object.keys(errors)[0];
                if (refs[firstErrorKey] && refs[firstErrorKey].current) {
                    refs[firstErrorKey].current.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        }
    };

    const resetForm = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            fax: '',
            company_name: '',
            designation: '',
            city: '',
            postal_code: '',
            country: '',
            product: "Please choose subject...",
            subject: '',
            message: '',
            confirm: false,
        })
    }

    return (

        <div className='contact-form'>

            <form
                data-parsley-validate="data-parsley-validate"
                data-validate="html5"
                encType="multipart/form-data"
                name="field"
                className="powermail_form powermail_form_1"
                onSubmit={handleSubmit}
                // action="/contact/contact-form/?tx_powermail_pi1%5Baction%5D=create&amp;tx_powermail_pi1%5Bcontroller%5D=Form&amp;cHash=74d576905f49cd2d17c8e7a91109c47b"
                // method="post"
                noValidate
            >
                <div className="conatctMainkl">
                    <h1 className="firstHeader">CONTACT FORM </h1>
                    <h2 className='ice-blue'>GET IN TOUCH WITH US </h2>
                </div>

                {/* Personal Data */}
                <fieldset className="powermail_fieldset powermail_fieldset_1">
                    <legend className="powermail_legend">Personal Data</legend>

                    {/* First Name */}
                    <div className="form-group conPage">
                        <input
                            placeholder="First name*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_firstname"
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            ref={refs.first_name}
                        />
                        {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                    </div>

                    {/* Last Name */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Last name*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_lastname"
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            ref={refs.last_name}
                        />
                        {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                    </div>

                    {/* E-Mail */}
                    <div className="form-group conPage">
                        <input
                            required
                            placeholder="E-Mail*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_e_mail"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            ref={refs.email}
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                    </div>

                    {/* Phone */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Phone*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_phone"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            ref={refs.phone}
                        />
                        {errors.phone && <p className="error-message">{errors.phone}</p>}

                    </div>

                    {/* Fax */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Fax"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_fax"
                            type="tel"
                            name="fax"
                            value={formData.fax}
                            onChange={handleChange}
                        />
                    </div>
                </fieldset>

                {/* Your Company / Location */}
                <fieldset className="powermail_fieldset powermail_fieldset_2">
                    <legend className="powermail_legend">Your Company / Location</legend>

                    {/* Company */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Company Name*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_company"
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            ref={refs.company_name}
                        />
                        {errors.company_name && <p className="error-message">{errors.company_name}</p>}

                    </div>

                    {/* Street */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Designation*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_street"
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            ref={refs.designation}
                        />
                        {errors.designation && <p className="error-message">{errors.designation}</p>}

                    </div>
                    <div className="form-group conPage">
                        <input
                            placeholder="City*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_street"
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            ref={refs.city}
                        />
                        {errors.city && <p className="error-message">{errors.city}</p>}

                    </div>

                    {/* Postal Code */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Postal code*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_postalcode"
                            type="text"
                            name="postal_code"
                            value={formData.postal_code}
                            onChange={handleChange}
                            ref={refs.postal_code}
                        />
                        {errors.postal_code && <p className="error-message">{errors.postal_code}</p>}

                    </div>

                    {/* City */}
                    <div className="form-group conPage" ref={refs.country}>
                        <Select
                            options={countryOptions}
                            placeholder="Select a country*"
                            value={countryOptions.find((option) => option.value === formData.country)}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'country')}
                        />
                        {errors.country && <p className="error-message">{errors.country}</p>}
                    </div>
                </fieldset>

                {/* Your Message */}
                <fieldset className="powermail_fieldset powermail_fieldset_3">
                    <legend className="powermail_legend">Your Message</legend>

                    {/* Subject Select */}
                    <div className="form-group conPage" ref={refs.product}>
                        <Select
                            //  className='contact-select'
                            placeholder="Select...*"
                            options={subjectOptions}
                            value={subjectOptions.find((option) => option.value === formData.product)}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'product')}


                        />
                        {errors.product && <p className="error-message">{errors.product}</p>}

                    </div>

                    {/* subject */}
                    <div className="form-group conPage">
                        <input
                            placeholder="Subject*"
                            className="form-control powermail_field powermail_input"
                            id="powermail_field_subject"
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            ref={refs.subject}
                        />
                        {errors.subject && <p className="error-message">{errors.subject}</p>}

                    </div>

                    {/* Message Textarea */}
                    <div className="form-group conPage">
                        {/* <label htmlFor="message">Message</label> */}
                        <textarea
                            rows="5"
                            cols="20"
                            placeholder="Your message*"
                            className="form-control powermail_textarea"
                            id="message"
                            type="text"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            ref={refs.message}
                        ></textarea>
                        {errors.message && <p className="error-message">{errors.message}</p>}

                    </div>

                    {/* Confirm Checkbox */}
                    <div className="powermail_fieldwrap powermail_fieldwrap_check">
                        <fieldset>

                            <div className="powermail_check_outer">
                                <div className="powermail_check_inner powermail_check_inner_1">
                                    <input
                                        type="checkbox"
                                        id="confirm"
                                        name="confirm"
                                        checked={formData.confirm}
                                        onChange={handleChange}
                                    />
                                    <label className='dectxt' htmlFor="declarationCheckbox">
                                        I hereby confirm that the data I have provided will be used to improve the website in accordance with the data protection regulations.

                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    {/* Submit Button */}
                    <div className="form-group conPage">
                        <button type="submit" className="btn btn-primary sendmsg" disabled={!formData.confirm}> {sending ? 'Sending...' : ' Send Message'}
                        </button>
                    </div>
                </fieldset>


            </form>
            <ToastContainer
                className="custom-toast-container"
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnMiddle
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default Contact;
