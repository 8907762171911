import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import PagesCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"



const AfterSalesandServices = () => {
    const pageImages = [

        {
            id: 1,
            image: Images.afterslaesServices1
        },
        {
            id: 2,
            image: Images.afterslaesServices2
        },
        {
            id: 3,
            image: Images.afterslaesServices3
        },
        {
            id: 4,
            image: Images.afterslaesServices4
        },

        {
            id: 5,
            image: Images.afterslaesServices5
        },
        {
            id: 6,
            image: Images.afterslaesServices6
        },


    ]
    return (
        <div className='aircooledreundling-main'>
            <div className='heading-text mb-3'>
                <h1>AFTER SALES & SERVICE</h1>
            </div>
            <div className='sub-heading headtext-section'>
                <h2 className='ice-blue'>ENEXIO SERVICE - THE LOW RISK DECISION </h2>
            </div>
            <div id="c1437" className='headtext-section mb-4'>
                <p className="">From original replacement parts to comprehensive retrofit projects, ENEXIO Service is always the reliable partner for your dry and wet cooling system to contact.</p>
                <p className="">
                    Through our own manufacturing facilities and a huge network of worldwide partners we can supply our patented ALEX Tube heat exchanger bundle, Splash fills, Drift Eliminators, Film Fills and Spray Nozzles to any destination, worldwide!
                </p>

            </div>
            <div>
                <PagesCarousel pageImages={pageImages} />
            </div>

        </div>



    )
}

export default AfterSalesandServices