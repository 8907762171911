import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import PagesCarousel from '../../commonComponents/PagesCarousel/PagesCarousel'

const HybridCoolingDelugeAcc = () => {
    const image1 = Images.hybridRefImg;
    const image2 = Images.hybridRefImg2;

    const pageImages = [

        {
            id: 1,
            image: Images.hybridRefImg3
        },
        {
            id: 2,
            image: Images.hybridRefImg4
        },
        {
            id: 3,
            image: Images.hybridRefImg5
        },

    ]


    return (
        <div className='aircooled-main'>
            <div className='heading-text mb-3'>
                <h1>AIR COOLED CONDENSER WITH DELUGE COOLING</h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>NEW HYBRID COOLING TECHNOLOGY - AIR COOLED CONDENSER WITH DELUGE COOLING</h2>
            </div>
            <div className='headtext-section'>
                <p className="lead">The Deluge Air cooled condenser is the latest technological achievement in hybrid cooling, where the primary interest is in dry cooling, but where limited water resources are available for use during certain periods of the year.</p>
                <p>ENEXIO is one of the leading consortium partners of the EU-funded Horizon 2020 Research and Innovation programme called “MinWaterCSP” with the aim to develop advanced cooling technologies as well as integrated water management plans to reduce cooling system water consumption by up to 95% relative to wet only cooling systems.
                </p>

            </div>
            <div className='csc-space-before-20 csc-space-after-20 headtext-section'>
                <div className='headtext-section'>
                    <div>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/CNDkUx9anqA?si=XJxH0703Awz2TDw5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='mb-3'>
                        <img src={image1} alt="hybridReference image" width="100%" />
                    </div>
                    <div> <p>The increase in scarcity of water resources worldwide is one of the main drivers towards the use of dry-cooling (cooling with air) for power plant cooling purposes instead of the more conventional wet-cooling (cooling with water) approach. However, even though dry-cooling methods allow an order of magnitude reduction in cooling water consumption, the overall power cycle efficiency is generally lower compared to cases where wet-cooling is implemented.</p></div>
                    <div>
                        <PagesCarousel pageImages={pageImages} />
                    </div>
                </div>
            </div>

            <div id='c8113' class="csc-space-before-20 headtext-section mb-4">
                <h3 className='mb-3'> SIGNIFICANT LOWER CAPEX COMPARED TO CONVENTIONAL PAC-SYSTEM</h3>
                <p className='mb-3'>The integrated hybrid cooling system consists of traditional dry air cooled condenser fan cells and a small number of wet cells, where steam is condensed inside tube bundles through external deluge cooling.
                </p>
                <div><img src={image2} alt="hybridReference image" width="100%" /></div>
            </div>
            <div id='c1448'>
                <div className='row headtext-section'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div id='c1449'>
                            <h4 className='text-center csc-firstHeader frst-typ'>Construction advantage</h4>
                            <div className='img-center-inner'>
                                <p>In comparison to the existing hybrid condensing systems, the Deluge Air Cooled Condenser eliminates the use of the separate wet-cooling towers, surface condenser and large cooling water recirculating piping and pumps, minimizing investment costs and plot area.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div id='c1449'>
                            <h4 className='text-center csc-firstHeader scnd-typ'>Operational advantage</h4>
                            <div className='img-center-inner'>
                                <p>Turbine backpressure and associated generator output can be maintained during peak ambient temperatures and under other adverse ambient conditions such as strong winds and hot air recirculation.</p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HybridCoolingDelugeAcc