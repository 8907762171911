import React from 'react'
import Images from '../../commonComponents/Images/Images'
import BannerCarousel from '../../commonComponents/bannerCarousel/bannerCarousel'

function HomepageTextContent() {


    return (
        <>
            <div className='homepagetext-main mb-5'>

                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                        <div>
                            <div className='image-heading'>
                                <img src={Images.flashimg} />
                                <h1>
                                    <span className='ice-blue'>YOUR RELIABLE PARTNER </span>
                                    FOR POWER PLANT AND INDUSTRY COOLING
                                </h1>
                            </div>
                            {/* <div className='image-heading csc-space-before-55'>
                            <img src={Images.waterdropimg} />
                            <h1>
                                <span className='ice-blue'> FUNCTIONAL SURFACES:</span> EFFICIENT SOLUTIONS FOR WATER AND AIR TREATMENT
                            </h1>
                        </div> */}
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='text-content'>
                            <p>The former Power Cooling division of GEA Group AG operates separately and independently under the name ENEXIO.</p>
                            <p>
                                ENEXIO is a reflection of what we do and what we have accomplished as pioneer in the field of industry, process, and power plant cooling over decades.
                                {/* At the same time, our name is a promise to our customers and business partners – ENEXIO stands for Energy. Engineering. Excellence. */}
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HomepageTextContent